@font-face {
  font-family: "Awesome Serif VAR";
  src: url("AwesomeSerifVAR-Light.woff") format("woff2"),
    url("AwesomeSerifVAR-Light.woff2") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@import "./vars";
@import "./mixins";
@import "./utils";
@import "./globals";
@import "./blocks";
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body,
html {
  margin: 0;
  padding: 0;
  background-color: var(--color-0);
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  font-weight: 400;
  scroll-behavior: auto;
  color: var(--color-1);
  background-color: var(--color-0);
}

body {
  overflow-x: hidden;
  padding: var(--spacing-7);
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 300;
  @include break(tablet) {
    padding: var(--spacing-4);
  }
}

*,
*::placeholder {
  font-family: var(--font-family-1);
}

*,
*::placeholder,
*::before,
*::after {
  box-sizing: inherit;
}

::selection {
  background-color: black;
  color: var(--color-2);
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
